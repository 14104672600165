//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'

//Packages
import Helmet from 'react-helmet'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Autoplay, EffectCards, EffectCoverflow, Pagination } from 'swiper'

//Components
import Layout from '../../components/layout'
import Metatags from '../../components/marketing/metatags'

// import HomeCallouts from '../../components/marketing/homeCallouts'
// import FeaturePreviewSection from '../../components/marketing/featurePreviewSection'
// import FeaturePreviewSectionPlatform from '../../components/marketing/featurePreviewSectionPlatform'

// import PostLink from '../../components/marketing/postLink'

// import TemplateCallouts from '../../components/marketing/templateCallouts'
// import FeatureIcon from '../../components/marketing/featureIcon'
// import PreviewVideo from '../../components/elements/previewVideo'
// import PromoSurveyWeb3 from '../../components/marketing/promoSurveyWeb3'
// import PromoCampaignEightUseCases from '../../components/marketing/promoCampaignEightUseCases'
// import PromoCampaignClimate from '../../components/marketing/promoCampaignClimate'
// import PromoCampaignContentAuth from '../../components/marketing/promoCampaignContentAuth'
// import PromoSocialImpact from '../../components/marketing/promoSocialImpact'

//Icons
import { FaArrowRight } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { BiLocationPlus } from 'react-icons/bi'
import { nodeName } from 'jquery'

//Styles
// import 'swiper/css'
// import 'swiper/css/effect-cards'
// import 'swiper/css/effect-coverflow'
// import 'swiper/css/pagination'

// const mediaPlaylist = [
//   // 'https://picovideomedia-prod.s3.amazonaws.com/public/u/a897ca3b-6509-4509-9b4f-4fb58a009348/export/2f7e18e0-a30f-4e07-9c57-cf8e28e2f62e/video.mp4',
//   // 'https://picovideomedia-prod.s3.amazonaws.com/public/u/d19bd929-c1b6-4330-9236-65075b12348a/export/f1d61a7a-5e42-4372-a7b6-d9533473d93d/video.mp4',
//   // 'https://picovideomedia-prod.s3.amazonaws.com/public/u/d19bd929-c1b6-4330-9236-65075b12348a/export/f983484e-13f9-4d64-9a64-9f0d4c6b3ebe/video.mp4',
//   '/video/video-drinks-1.mp4',
//   '/video/service.mp4',
//   '/video/video-tractor-1.mp4',
//   // 'https://picovideomedia-prod.s3.amazonaws.com/public/u/d19bd929-c1b6-4330-9236-65075b12348a/export/69d6c930-656b-4d1f-8331-75e9e0cd3ffc/video.mp4',
//   // '/video/gameday.mp4',
//   // 'https://picovideomedia-prod.s3.amazonaws.com/public/u/a897ca3b-6509-4509-9b4f-4fb58a009348/export/00effa5c-20dc-40dd-8f39-e5903fceddb3/video.mp4',
// ]

const Snippet = (props) => {
  console.log('props')
  console.log(props)
  let mergedPosts = []
  if (props && props.data && props.data.articles) {
    // let blogLimit = 3
    mergedPosts = props.data.articles.edges
    // blogPosts = props.data.blogs.edges.slice(0, blogLimit)
    // console.log('blogPosts')
    // console.log(blogPosts)
    // mergedPosts = [...blogPosts]
    // mergedPosts.sort((a, b) => (a.dateVal > b.dateVal ? -1 : 1))
  }

  // console.log('mergedPosts')
  // console.log(mergedPosts)
  // const Posts = mergedPosts
  // .filter(
  //   (edge) =>
  //     edge.node.frontmatter.date && edge.node.frontmatter.publish === 1
  // ) // You can filter your posts based on some criteria
  // .map((edge) => <PostLink home={true} key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Metatags
        title="Sidepath: Travel"
        description="Discover the world with Sidepath"
        image={`https://${process.env.HOSTNAME}/static/090f890271a1fd377e4e96d4fb37fb22/ed2d0/feature-loyalty-design-clean-alt-short-R.png`}
      />

      {/* <div class="flex-grow-1 pt-2 pb-4"> */}
      <div class="container">
        <div class="row d-flex">
          <div class="col-md-12 rounded-corners text-center p-2 py-4">
            {/* <div class="d-flex justify-content-center align-items-center mb-3">
                <FeatureIcon context="coin" />
              </div> */}
            <h2 class="font-weight-bold py-2">Travel guides</h2>
            {/* <p class="lead text-center display-4 white-1">
                Articles to help you attract new customers using Web3
              </p> */}
            <div className="row d-flex flex-column align-items-start justify-content-start text-left">
              {/* {Posts} */}
              <ul>
                {mergedPosts && mergedPosts.length > 0
                  ? mergedPosts.map((post) => (
                      <li>
                        <Link
                          to={`/${post.node.data.area
                            .toLowerCase()
                            .replaceAll(' ', '-')}/${post.node.data.region
                            .toLowerCase()
                            .replaceAll(' ', '-')}/${post.node.data.url}`}
                        >
                          {post.node.data.title}
                        </Link>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            {/* <Link
                to="/resources/"
                class="btn btn-primary btn-cta-outline mt-4"
              >
                <div>
                  Explore all Web3 resources <FaArrowRight class="btn-arrow" />
                </div>
              </Link> */}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* <div class="flex-grow-1">
        <StaticImage
          src="../images/stock/unsplash/taras-zaluzhnyi-jkXaik_8HCA-unsplash.jpg"
          alt="Sidepath"
          loading="lazy"
          placeholder="blurred"
          layout="fullWidth"
          // width={800}
          // height={500}
          // style={{ position: `absolute` }}
          class="home-image"
          style={{
            position: `absolute`,
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            inset: 'auto',
            width: '100%',
            height: '100%',
            // height: calc(100vw - 59px - 73.5px),
            zIndex: 0,
          }}
          imgStyle={{ objectFit: 'contain', height: '100%' }}
        />
      </div> */}
    </Layout>
  )
}
export default Snippet

export const pageQuery = graphql`
  query {
    articles: allAirtable(
      filter: { table: { eq: "article" }, data: { publish: { eq: 1 } } }
    ) {
      edges {
        node {
          id
          data {
            id
            publish
            publishdate
            area
            region
            guide
            title
            desc
            url
            tags
            markdown {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  }
`
