//React
import React from 'react'

import Helmet from 'react-helmet'

const Snippet = ({ title, description, image }) => (
  <Helmet
    title={title}
    meta={[
      {
        name: 'description',
        property: 'description',
        content: description,
      },
      {
        name: 'og:image',
        property: 'og:image',
        content: image,
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:site',
        content: '@piconexthq',
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:image',
        content: image,
      },
      {
        name: 'twitter:description',
        content: description,
      },
      {
        name: 'keywords',
        content:
          'Web3, loyalty, marketing, brand engagement, decentralized, blockchain, social impact',
      },
      {
        name: 'og:site_name',
        content: 'Sidepath',
      },
      {
        name: 'og:title',
        content: title,
      },
      {
        name: 'author',
        content: 'Sidepath',
      },
      {
        name: 'og:type',
        content: 'website',
      },
    ]}
  />
)
export default Snippet
